
// import { computed, defineComponent, ref, watch } from 'vue'
// import { Actions } from '@/store/enums/StoreEnums'
// import { useStore } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { buildTemplateImageUri } from '@/core/mc-admin-helpers/activity-template'
import { TabsPaneContext } from 'element-plus'

export default defineComponent({
  name: 'the-activity-edit-modal',
  components: {},
  setup () {
    const aiContext = ref('')
    const activeSettingsTabName = ref('generalSettings')
    const handleClickSettingsTab = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event)
    }
    const targetData = reactive({
      title: '',
      description: '',
      duration: 0,
      introDuration: 5 as any,
      challengeDuration: 30 as any,
      debriefingDuration: 5 as any
    })
    const fileUpload = ref()
    const uploadedFile = ref()
    const existingUploadedFileDetails = ref()
    const uploadFileProgress = (widgetUuid) => computed(() => store.getters.getFileUploadProgressForWidget(activityTemplate.value.activityTemplateUuid))

    const onFileUploaded = (event) => {
      console.log('name :', event.target.files[0].name)
      console.log('type :', event.target.files[0].type)
      console.log('size :', event.target.files[0].size)
      console.log('last modified :', event.target.files[0].lastModified)
      console.log('file.value :', event.target.files[0])
      const updateProgressPayload = {
        widgetUuid: activityTemplate.value.activityTemplateUuid,
        progress: 0
      }
      store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
      const data = new FormData()
      data.append('content', event.target.files[0])
      data.append('templateUuid', activityTemplate.value.activityTemplateUuid)
      data.append('aiTrainer', 'true')

      const config = {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2) },
        onUploadProgress: (progressEvent) => {
          updateProgressPayload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        }
      }
      const payload = {
        data: data,
        config: config
      }

      store.dispatch(Actions.API_UPLOAD_FILE_ACTIVITY_TEMPLATE, payload).then((response) => {
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        // const updatePayload = {
        //   widgetUuid: activityTemplate.value.activityTemplateUuid,
        //   attachmentMeta: response.payload.meta
        // } as any
        existingUploadedFileDetails.value = response.payload.meta
      }).catch((response) => {
        fileUpload.value.value = null
        updateProgressPayload.progress = 0
        store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }
    const removeFileLoading = ref([]) as any

    const onFileRemoved = (templateUuid, attachmentUuid) => {
      removeFileLoading.value.push(attachmentUuid)
      const payload = {
        templateUuid: templateUuid,
        attachmentUuid: attachmentUuid
      }
      store.dispatch(Actions.API_DELETE_ACTIVITY_TEMPLATE_ATTACHMENT, payload).then((response) => {
        removeFileLoading.value = removeFileLoading.value.filter(e => e !== attachmentUuid)
        existingUploadedFileDetails.value = null
        uploadedFile.value.value = null
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
        removeFileLoading.value = removeFileLoading.value.filter(e => e !== attachmentUuid)
      })
    }
    const rules = computed(() => {
      return {
        title: [
          {
            required: true,
            message: 'Please input template title',
            trigger: 'change'
          }
        ],
        description: [
          {
            required: true,
            message: 'Please input template description',
            trigger: 'change'
          }
        ],
        introDuration: [
          {
            required: true,
            message: 'required field',
            trigger: 'change'
          }
        ],
        challengeDuration: [
          {
            required: true,
            message: 'required field',
            trigger: 'change'
          }
        ],
        debriefingDuration: [
          {
            required: true,
            message: 'required field',
            trigger: 'change'
          }
        ]
      }
    })
    const currentAccount = computed(() => store.getters.currentAccount)
    const checkedTag = ref([]) as any
    const usedTags = ref([]) as any
    const onChangeTag = (tagKey, status: boolean) => {
      checkedTag.value[tagKey] = status
      if (status) {
        usedTags.value.push(tagKey)
      } else {
        // remove them from the used tags array if not checked
        usedTags.value = usedTags.value.filter((tag) => tag !== tagKey)
      }
    }
    const introDuration = ref(5) as any
    const challengeDuration = ref(30) as any
    const debriefingDuration = ref(5) as any
    const totalDuration = ref(parseInt(targetData.introDuration) + parseInt(targetData.challengeDuration) + parseInt(targetData.debriefingDuration)) as any
    const calculateTotalDuration = () => {
      totalDuration.value = parseInt(targetData.introDuration) + parseInt(targetData.challengeDuration) + parseInt(targetData.debriefingDuration)
    }
    const store = useStore()
    const file = ref('')
    const activityUuid = ref('')
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const imageUri = ref('')
    const formRef = ref<null | HTMLFormElement>(null)
    const updateInProgress = ref(false)
    const target = ref(100) as any
    const imageUuid = ref()
    watch(activityTemplate, (newActivityTemplate) => {
      activityUuid.value = newActivityTemplate.activityTemplateUuid
      imageUuid.value = newActivityTemplate.image
      targetData.title = newActivityTemplate.title
      targetData.description = newActivityTemplate.description
      targetData.introDuration = newActivityTemplate.introDuration / 60
      targetData.challengeDuration = newActivityTemplate.challengeDuration / 60
      targetData.debriefingDuration = newActivityTemplate.debriefingDuration / 60
      target.value = newActivityTemplate.targetScore
      // targetData.duration = newActivityTemplate.duration / 60
      calculateTotalDuration()
      imageUri.value = buildTemplateImageUri(imageUuid.value, currentAccount.value.company.name)
      usedTags.value = JSON.parse(newActivityTemplate.tags)
      usedTags.value.forEach((tag, key) => {
        checkedTag.value[tag] = true
      })
      aiContext.value = newActivityTemplate.aiContext
      existingUploadedFileDetails.value = newActivityTemplate.templateJson.aiTrainerDocument
    })
    const newAccount = ref({})
    const company = ref()
    watch(currentAccount, (updatedAccount) => {
      newAccount.value = updatedAccount
      company.value = updatedAccount.company.name
    })

    const onChange = (event) => {
      if (event.target.files.length === 0) {
        file.value = ''
        return
      }

      file.value = event.target.files[0]
    }
    watch(file, (value: any) => {
      if (!(value instanceof File)) {
        return
      }
      const fileReader = new FileReader()

      fileReader.readAsDataURL(value)
      fileReader.addEventListener('load', () => {
        imageUri.value = fileReader.result as string
      })
    })
    const formSubmit = () => {
      updateInProgress.value = true
      if (!formRef.value) {
        return
      }
      const isFormValid = ref(true)
      formRef.value.validate((valid) => {
        if (!valid) {
          isFormValid.value = false
        }
      })

      if (!isFormValid.value) {
        return
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
        }
      }
      const data = new FormData()
      if (file.value !== '') {
        data.append('image', file.value)
      }
      data.append('title', targetData.title)
      data.append('description', targetData.description)
      data.append('duration', (totalDuration.value * 60).toString())
      data.append('introDuration', (targetData.introDuration * 60).toString())
      data.append('challengeDuration', (targetData.challengeDuration * 60).toString())
      data.append('debriefingDuration', (targetData.debriefingDuration * 60).toString())
      data.append('updatedAt', new Date().toISOString())
      data.append('targetScore', target.value)
      data.append('tags', JSON.stringify(usedTags.value))
      data.append('aiContext', aiContext.value)

      const payload = {
        data: data,
        templateUuid: activityTemplate.value.activityTemplateUuid,
        config: config
      }
      store.dispatch(Actions.API_UPDATE_ACTIVITY_TEMPLATE, payload).then((response) => {
        updateInProgress.value = false
        imageUri.value = response.payload.sizes.size420 + '&company=' + currentAccount.value.company.name
        activityTemplate.value.title = response.payload.title
        document.getElementById('closeModalUpdateActivity')?.click()
      }).catch((response) => {
        updateInProgress.value = false
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
      onMounted(() => {
        if (activityTemplate.value.aiTrainerDocument) {
          uploadedFile.value.value = null // Clear any previously selected file
          uploadedFile.value.files = null // Clear file selection
          uploadedFile.value.files = [new File([], activityTemplate.value.aiTrainerDocument.name)]
        }
      })
    }
    return {
      formSubmit,
      onChange,
      checkedTag,
      onChangeTag,
      updateInProgress,
      formRef,
      rules,
      activityTemplate,
      targetData,
      imageUri,
      target,
      introDuration,
      challengeDuration,
      debriefingDuration,
      totalDuration,
      calculateTotalDuration,
      activeSettingsTabName,
      handleClickSettingsTab,
      aiContext,
      onFileUploaded,
      uploadFileProgress,
      existingUploadedFileDetails,
      fileUpload,
      uploadedFile,
      currentAccount,
      company,
      newAccount,
      onFileRemoved
    }
  }
})
