
import { ref, computed, onMounted, watch } from 'vue'
import _ from 'lodash'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { Widget } from '@/core/helpers/template/TemplateInterface'
import { generateWidget } from '@/core/helpers/template/widget'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

export default {
  props: {
    modalOpened: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const selectedWidget = ref()
    const selectedWidgetLabel = ref()
    const widgetSelector = ref('')
    const showWidgetsSelector = ref(true)
    const selectedMatchIndex = ref(-1)
    const keyword = ref('')
    const widgetPlaceholder = ref('Choose Widget')
    const widgetSearch = ref()
    const inputSearchPhotos = ref()
    const keywordPhotos = ref('')
    const loadingSearch = ref(false)
    const showSearchPhotos = ref(false)
    const unsplashPhotos = ref([])
    function updatePlaceholder () {
      if (!widgetSelector.value) {
        widgetPlaceholder.value = 'Choose Widget'
        selectedWidget.value = ''
        selectedMatchIndex.value = -1
        return
      }

      const bestMatch = filteredWidgets.value[0]
      if (bestMatch) {
        widgetPlaceholder.value = widgetSelector.value + bestMatch.name.slice(widgetSelector.value.length)
        selectedWidget.value = bestMatch.id
        selectedMatchIndex.value = 0
      } else {
        selectedWidget.value = ''
        widgetPlaceholder.value = ''
      }
    }

    function selectPreviousMatch () {
      if (selectedMatchIndex.value === -1) {
        selectedMatchIndex.value = filteredWidgets.value.length - 1
      } else if (selectedMatchIndex.value > 0) {
        selectedMatchIndex.value--
      }
      if (selectedMatchIndex.value > -1) {
        widgetPlaceholder.value = widgetSelector.value + filteredWidgets.value[selectedMatchIndex.value].name.slice(widgetSelector.value.length)
        selectedWidget.value = filteredWidgets.value[selectedMatchIndex.value].id
      }
    }

    function selectNextMatch () {
      if (selectedMatchIndex.value === filteredWidgets.value.length - 1) {
        selectedMatchIndex.value = filteredWidgets.value.length - 1
      } else if (selectedMatchIndex.value !== -1) {
        selectedMatchIndex.value++
      } else if (selectedMatchIndex.value === -1) {
        selectedMatchIndex.value++
      }

      if (selectedMatchIndex.value > -1) {
        widgetPlaceholder.value = widgetSelector.value + filteredWidgets.value[selectedMatchIndex.value].name.slice(widgetSelector.value.length)
        selectedWidget.value = filteredWidgets.value[selectedMatchIndex.value].id
      }
    }
    function onSelectWidget (e, widgetIndex = null) {
      if (widgetIndex !== null) {
        selectedWidget.value = widgets.value.find((item, i) => { return i === widgetIndex }).id
      }
      if (selectedWidget.value === '') {
        return
      }
      showWidgetsSelector.value = false
      if (selectedWidget.value) {
        selectedWidgetLabel.value = widgets.value.find(item => item.id === selectedWidget.value).name
        if (selectedWidget.value === 'image-card') {
          showSearchPhotos.value = true
          showTextarea.value = false
          if (unsplashPhotos.value.length === 0) {
            store.dispatch(Actions.API_UNSPLASH_SEARCH_PHOTOS).then((result) => {
              unsplashPhotos.value = result.payload.photos
            })
          }

          setTimeout(() => {
            keywordPhotos.value = ''
            inputSearchPhotos.value.focus()
          }, 0)
        } else {
          showTextarea.value = true
          showSearchPhotos.value = false
          setTimeout(() => {
            widgetMagicInput.value = ''
            textArea.value.focus()
          }, 0)
        }
      } else {
        showTextarea.value = false
      }
    }

    function removeSelectedWidget () {
      selectedWidget.value = ''
      showTextarea.value = false
      showWidgetsSelector.value = true
      showSearchPhotos.value = false
      widgetPlaceholder.value = 'Choose Widget'
      selectedMatchIndex.value = -1
      widgetSelector.value = ''
      setTimeout(() => {
        widgetSearch.value.focus()
      }, 0)
    }

    const widgets = ref([
      { id: 'text-card-styled', name: 'Anything', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'text-card-styled-definition', name: 'Definition', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'text-card-styled-course-intro', name: 'Course Intro', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'text-card-styled-reading-list', name: 'Reading list', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'text-card-styled-real-world-scenario', name: 'Real-world problem scenario', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'text-card-styled-fictional-character', name: 'Fictional character description', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'text-card-styled-fictional-company', name: 'Fictional company description', categoryIcon: '<i style="color: #7ad3ff;" class="las la-stop"></i>' },
      { id: 'heading', name: 'Heading', categoryIcon: '<i style="color: #7ad3ff;" class="las la-heading"></i>' },
      { id: 'flashcard', name: 'Callout', categoryIcon: '<i style="color: #7ad3ff;" class="las la-bullhorn"></i>' },
      { id: 'image-card', name: 'Image', categoryIcon: '<i style="color: #7ad3ff;" class="las la-image"></i>' },
      { id: 'drag-and-drop-order', name: 'Order', categoryIcon: '<i style="color: tomato" class="las la-sort"></i>' },
      { id: 'question-short-answer', name: 'Short Answer', categoryIcon: '<i style="color: tomato" class="las la-comment-dots"></i>' },
      { id: 'question-single-choice', name: 'Single Choice', categoryIcon: '<i style="color: tomato" class="las la-check"></i>' },
      { id: 'question-multiple-choice', name: 'Multiple Choice', categoryIcon: '<i style="color: tomato" class="las la-check-double"></i>' }

    ]) as any

    const filteredWidgets = computed(() => {
      return widgets.value.filter(widget => widget.name.toLowerCase().startsWith(widgetSelector.value.toLowerCase()))
    })

    const widgetMagicInput = ref('')
    const addNewLine = () => {
      // widgetMagicInput.value = `${widgetMagicInput.value}\n`
    }

    function downloadAndAttachUrl (downloadUrl) {
      store.dispatch(Actions.API_UNSPLASH_FETCH_IMAGE, downloadUrl).then((result) => {
        const widget:Widget|null = generateWidget(selectedWidget.value, 0, 0)
        if (widget) {
          // apply the color back from dummy widget
          // widgetData.widget.specific.color = widgetData.widget.specific?.color
          const socketPayload = {
            widgetType: selectedWidget.value,
            imageUrl: result.url,
            templateUuid: activityTemplate.value.activityTemplateUuid,
            locator: {
              activeSectionIndex: activeSectionIndex.value
            },
            widget: widget
          }
          const payload = { search: widgetMagicInput.value, widget: socketPayload }
          store.dispatch(Actions.API_AI_GENERATE_WIDGET, payload).then((result) => {
            loadingSearch.value = false
            widgetMagicInput.value = ''
            selectedWidget.value = ''
            keywordPhotos.value = ''
            showSearchPhotos.value = false
            showTextarea.value = false
            showWidgetsSelector.value = true
            emit('finished-generating')
          })
        }
      })
    }
    const showTextarea = ref(false)
    const textArea = ref()
    const activityTemplate = computed(() => { return store.getters.getActivityTemplate })
    const activeSectionIndex = computed(() => { return store.getters.getActiveSectionIndex })

    function onSubmitSearchPhoto () {
      store.dispatch(Actions.API_UNSPLASH_SEARCH_PHOTOS, { page: 0, query: keywordPhotos.value }).then((result) => {
        unsplashPhotos.value = result.payload.photos.results ? result.payload.photos.results : result.payload.photos
      })
    }

    function submit () {
      if (_.isEmpty(widgetMagicInput.value)) {
        return
      }
      loadingSearch.value = true

      let textCardSubtype = null as any
      const textCardSubtypeMappings = {
        'text-card-styled-real-world-scenario': 'real-world-scenario',
        'text-card-styled-definition': 'definition',
        'text-card-styled-fictional-character': 'fictional-character',
        'text-card-styled-fictional-company': 'fictional-company',
        'text-card-styled-reading-list': 'reading-list',
        'text-card-styled-course-intro': 'course-intro'
      }

      const selectedWidgetValue = selectedWidget.value
      if (textCardSubtypeMappings[selectedWidgetValue]) {
        selectedWidget.value = 'text-card-styled'
        textCardSubtype = textCardSubtypeMappings[selectedWidgetValue]
      }

      const widget:Widget|null = generateWidget(selectedWidget.value, 0, 0)
      if (widget) {
        if (widget.type === 'text-card-styled') {
          widget.specific.color = 'grey-light'
          widget.position.h = 59
          widget.position.w = 24
        }
        // apply the color back from dummy widget
        // widget.widget.specific.color = 'grey-light'
        const socketPayload = {
          widgetType: selectedWidget.value,
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: activeSectionIndex.value
          },
          widget: widget
        }
        const payload = { search: widgetMagicInput.value, widget: socketPayload, textCardSubtype: textCardSubtype }
        store.dispatch(Actions.API_AI_GENERATE_WIDGET, payload).then((result) => {
          loadingSearch.value = false
          widgetMagicInput.value = ''
          selectedWidget.value = ''
          keywordPhotos.value = ''
          showSearchPhotos.value = false
          showTextarea.value = false
          showWidgetsSelector.value = true
          emit('finished-generating')
        }).catch((err) => {
          console.log(err)
          Swal.fire({
            text: err.data.error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Try again',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
          loadingSearch.value = false
          widgetMagicInput.value = ''
          selectedWidget.value = ''
          keywordPhotos.value = ''
          showSearchPhotos.value = false
          showTextarea.value = false
          showWidgetsSelector.value = true
        })
      }
    }
    watch(props, (newVal, oldVal) => {
      if (props.modalOpened) {
        if (widgetSearch.value) {
          setTimeout(() => {
            widgetSearch.value.focus()
          }, 0)
        }
        if (textArea.value) {
          setTimeout(() => {
            textArea.value.focus()
          }, 0)
        }
        if (inputSearchPhotos.value) {
          setTimeout(() => {
            inputSearchPhotos.value.focus()
          }, 0)
        }
      } else {
        removeSelectedWidget()
      }
    })
    onMounted(() => {
      setTimeout(() => {
        widgetSearch.value.focus()
      }, 0)
    })
    return {
      widgetMagicInput,
      onSelectWidget,
      removeSelectedWidget,
      selectPreviousMatch,
      selectNextMatch,
      selectedMatchIndex,
      filteredWidgets,
      widgetSelector,
      widgetPlaceholder,
      selectedWidgetLabel,
      updatePlaceholder,
      widgets,
      showWidgetsSelector,
      selectedWidget,
      downloadAndAttachUrl,
      onSubmitSearchPhoto,
      widgetSearch,
      unsplashPhotos,
      inputSearchPhotos,
      showSearchPhotos,
      keywordPhotos,
      loadingSearch,
      addNewLine,
      submit,
      textArea,
      keyword,
      showTextarea
    }
  }
}
