
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import vClickOutside from 'click-outside-vue3'
const _ = require('lodash')

export default defineComponent({
  name: 'kt-activity-drawer',
  components: {

  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup () {
    const emitter = inject('emitter') as any

    const store = useStore()
    const loading = ref(false)
    const aiPoweredSearchKeywords = ref<string>('')
    const searchResponse = ref<string>('')
    const latestMessages = computed(() => store.getters.getLatestMessages)
    const avatarPath = ref('') as any
    const imageUri = computed(() => avatarPath.value)
    const onClickOutside = () => {
      emitter.emit('clicked-outside-open-ai-drawer-builder')
    }
    const onCloseRobotAiModal = () => {
      emitter.emit('clicked-outside-open-ai-drawer-builder')
    }
    const handler = (event) => {
      emitter.emit('clicked-outside-open-ai-drawer-builder')
    }
    // Note: The middleware will be executed if the event was fired outside the element.
    //       It should have only sync functionality and it should return a boolean to
    //       define if the handler should be fire or not
    const middleware = (event) => {
      if (!event || !event.target) return false
      const className = event.target.className
      return typeof className === 'string' && className.indexOf('robot-ai') === -1
    }
    const vcoConfig = {
      handler: handler,
      middleware: middleware,
      events: ['dblclick', 'click'],
      // Note: The default value is true, but in case you want to activate / deactivate
      //       this directive dynamically use this attribute.
      isActive: true,
      // Note: The default value is true. See "Detecting Iframe Clicks" section
      //       to understand why this behavior is behind a flag.
      detectIFrame: true,
      // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
      //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
      capture: false
    }
    const addNewLine = () => {
      aiPoweredSearchKeywords.value = `${aiPoweredSearchKeywords.value}\n`
    }
    const messageContent = ref<HTMLDivElement | null>(null)
    const lottieOptions = {
      minimizable: false,
      playerSize: 'standard',
      backgroundColor: '#fff',
      backgroundStyle: 'color',
      theme: {
        controlsView: 'standard',
        active: 'light',
        light: {
          color: '#3D4852',
          backgroundColor: '#fff',
          opacity: '0.7'
        },
        dark: {
          color: '#fff',
          backgroundColor: '#202020',
          opacity: '0.7'
        }
      }
    }
    const receivedAiMessageStyle = {
      borderRadius: '6px',
      background: '#E6F6FF',
      color: '#191C30'
    }
    const generalMessageStyle = {
      margin: '1rem 1rem 1rem 0.7rem',
      boxSizing: 'border-box',
      padding: '0.6rem 1rem',
      borderRadius: '6px',
      background: '#33393C',
      color: '#ffffff',
      width: 'fit-content',
      maxWidth: '88%',
      boxShadow: '0 0 2rem rgb(0 0 0 / 8%), 0rem 1rem 1rem -1rem rgb(0 0 0 / 10%)'
    }
    const resetConversation = () => {
      store.dispatch(Actions.API_AI_RESET_CONVERSATION).then((result) => {
        // searchResponse.value = result.payload.choices[0].text.replace(/^\s+/, '').replace(/^\s+/, '')
        // const aiMessage = { } as any
        // aiMessage.content = searchResponse.value
        // aiMessage.generatedByAi = true
        // store.commit(Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES, aiMessage)
        // div.scrollIntoView()
        // loading.value = false
      })
    }
    const submit = () => {
      if (_.isEmpty(aiPoweredSearchKeywords.value)) {
        return
      }
      searchResponse.value = ''
      loading.value = true
      const userMessage = { } as any
      userMessage.content = aiPoweredSearchKeywords.value
      userMessage.generatedByAi = false
      store.commit(Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES, userMessage)
      const keywords = aiPoweredSearchKeywords.value
      aiPoweredSearchKeywords.value = ''
      const div = messageContent.value!
      div.scrollIntoView()
      store.dispatch(Actions.API_AI_SEARCH_BY_KEYWORDS, keywords).then((result) => {
        searchResponse.value = result.payload.choices[0].message.content.replace(/^\s+/, '').replace(/^\s+/, '')
        const aiMessage = { } as any
        aiMessage.content = searchResponse.value
        aiMessage.generatedByAi = true
        store.commit(Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES, aiMessage)
        div.scrollIntoView()
        loading.value = false
      })
    }
    const currentAccount = ref(computed(() => store.getters.currentAccount))

    onMounted(() => {
      const payloadAvatar = {} as any

      if (currentAccount.value) {
        payloadAvatar.accountUuid = currentAccount.value.accountUuid
        payloadAvatar.avatarPath = currentAccount.value.profileAvatarPath
        store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
          if (response.payload.image !== null) {
            avatarPath.value = response.payload.image
          } else {
            avatarPath.value = getMedia('/icons/admin-default-icon.png')
          }
        })
      }

      watch(() => currentAccount.value, (updated, oldAccount) => {
        payloadAvatar.accountUuid = updated.accountUuid
        payloadAvatar.avatarPath = updated.profileAvatarPath
        store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
          if (response.payload.image !== null) {
            avatarPath.value = response.payload.image
          } else {
            avatarPath.value = getMedia('/icons/admin-default-icon.png')
          }
        })
        store.dispatch(Actions.API_AI_GET_LATEST_MESSAGES).then((result) => {
        })
      })
    })
    return {
      loading,
      searchResponse,
      aiPoweredSearchKeywords,
      latestMessages,
      generalMessageStyle,
      receivedAiMessageStyle,
      addNewLine,
      submit,
      resetConversation,
      messageContent,
      lottieOptions,
      imageUri,
      getMedia,
      onClickOutside,
      vcoConfig,
      onCloseRobotAiModal
    }
  }
})
