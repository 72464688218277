
import { computed, defineComponent, inject, ref } from 'vue'
import { Lock, ChatDotRound } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus/es'
import store from '@/store'

export default defineComponent({
  name: 'the-lock-section-modal',
  components: {

  },
  setup () {
    const dialogVisible = ref(false)
    const emitter = inject('emitter') as any
    const selectedSection = ref({})
    const selectedSectionIndex = ref(-1)
    const lockedPassword = ref('')
    const lockedPasswordHint = ref('')
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const socket = computed(() => store.getters.getSocket)

    emitter.on('show-lock-section-modal', (payload) => {
      dialogVisible.value = payload.state
      selectedSection.value = payload.section
      lockedPassword.value = ''
      lockedPasswordHint.value = ''
      if (payload.section.settings.lockedPassword) {
        lockedPassword.value = payload.section.settings.lockedPassword
      }
      if (payload.section.settings.lockedPasswordHint) {
        lockedPasswordHint.value = payload.section.settings.lockedPasswordHint
      }
      if (payload.sectionIndex) {
        selectedSectionIndex.value = payload.sectionIndex
      }
    })

    const confirm = () => {
      if (lockedPassword.value.length === 0 || lockedPasswordHint.value.length === 0) {
        ElNotification.warning({
          message: 'Please provide a password and a hint for locking the section',
          customClass: 'houston-notification'
        })
        return
      }
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: {
          activeSectionIndex: selectedSectionIndex.value
        },
        section: {
          settings: {
            enabled: false,
            locked: true,
            lockedPassword: lockedPassword.value,
            lockedPasswordHint: lockedPasswordHint.value
          }
        }
      }

      socket.value.emit('EDIT_SECTION', {
        eventType: 'EDIT_SECTION',
        payload: socketPayload
      })
      dialogVisible.value = false
    }

    return {
      dialogVisible,
      selectedSection,
      lockedPassword,
      lockedPasswordHint,
      Lock,
      ChatDotRound,
      confirm
    }
  }
})
