
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { Mutations } from '@/store/enums/StoreEnums'

export default defineComponent({
  name: 'builder-mobile-top-menu',
  components: {},
  setup () {
    const store = useStore()

    const countdown = ref(new Date()) as any
    countdown.value.setHours(1)
    countdown.value.setMinutes(29)
    countdown.value.setSeconds(11)

    const countdownHours = ref(countdown.value.getHours().toString().padStart(2, '0'))
    const countdownMinutes = ref(countdown.value.getMinutes().toString().padStart(2, '0'))
    const countdownSeconds = ref(countdown.value.getSeconds().toString().padStart(2, '0'))

    setInterval(() => {
      countdown.value.setSeconds(countdown.value.getSeconds() - 1)
      countdownHours.value = countdown.value.getHours().toString().padStart(2, '0')
      countdownMinutes.value = countdown.value.getMinutes().toString().padStart(2, '0')
      countdownSeconds.value = countdown.value.getSeconds().toString().padStart(2, '0')
    }, 1000)

    const revealMobileHiddenWidgetsChecked = computed(() => store.getters.getRevealMobileHiddenWidgets)
    const onRevealHiddenWidgetsClicked = () => {
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_REVEAL_MOBILE_HIDDEN_WIDGETS, !revealMobileHiddenWidgetsChecked.value)
    }

    const onDesktopViewClicked = () => {
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE, false)
      // when switching to desktop view mode, trigger resize event to scale builder properly
      const event = new Event('resize')
      window.dispatchEvent(event)
    }

    return {
      countdownHours,
      countdownMinutes,
      countdownSeconds,
      revealMobileHiddenWidgetsChecked,
      onRevealHiddenWidgetsClicked,
      onDesktopViewClicked
    }
  }
})
