<template>
    <div class="add-widget-from-input-wrapper">
        <el-dialog @open="onModalOpen" @close="onModalClose" v-model="isModalVisible" :show-close="false" custom-class="el-overlay-no-background">
            <template #title>
                <div class="d-flex flex-column align-self-center" style="line-height: 0">
                    <div class="d-flex align-items-center">
                        <h3 class="card-title fw-bolder " style="color: #E4E9F4">Mindstream</h3>
                        <!--                  <i class="las la-robot fs-2" ></i>-->
                        <span style="color: #459EF0">#</span>
                    </div>

                    <span class="fs-9 text-muted" style="color: #E5E7EE">Your friendly AI companion</span>
                </div>
            </template>
            <TheWidgetSearchAndAddModal :modal-opened="isModalOpen" @finished-generating="onFinishGenerating" />

        </el-dialog>
    </div>

</template>

<script>
import { ref } from 'vue'
import TheWidgetSearchAndAddModal from '@/components/builder/TheWidgetSearchAndAddModal.vue'

export default {
  name: 'the-widget-add-modal',
  components: { TheWidgetSearchAndAddModal },
  setup () {
    const isModalVisible = ref(false)
    const isModalOpen = ref(false)
    window.addEventListener('keydown', (event) => {
      if (event.metaKey && event.code === 'KeyK') {
        isModalVisible.value = !isModalVisible.value
      }
    })

    function onModalOpen () {
      isModalOpen.value = true
    }
    function onModalClose () {
      isModalOpen.value = false
    }

    function onFinishGenerating () {
      isModalVisible.value = false
    }
    return {
      isModalVisible,
      isModalOpen,
      onFinishGenerating,
      onModalOpen,
      onModalClose
    }
  }
}
</script>

<style>

.el-overlay-no-background {
    background: rgb(21, 23,24);
    border-radius: 12px;
}
.add-widget-from-input-wrapper .el-overlay {
    background-color: revert;
}

.el-overlay-no-background .el-dialog__body {
    padding-top: 15px;
    padding-bottom: 5px;
}
/*.el-overlay-no-background .el-dialog__header {*/
/*    padding: 0 !important;*/
/*}*/
.el-overlay-no-background .el-dialog__body {
    border-radius: 6px;
}
</style>

<style scoped>

div.el-dialog__header {
    padding: 0 !important;
}
.el-overlay-no-background {
    background-color: revert !important;
}
</style>
