import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21621070"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-iconfinder-content" }
const _hoisted_2 = { class: "iconfinder-search" }
const _hoisted_3 = {
  key: 0,
  style: {"top":"50px"}
}
const _hoisted_4 = ["onDrag", "onDragend", "onClick", "onMouseup", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-iconfinder", { 'active': $props.showModal }]),
    style: _normalizeStyle({ height: $setup.height + 78 + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onScroll: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.onScrollItems && $setup.onScrollItems(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_input, {
            modelValue: $setup.searchQuery,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchQuery) = $event)),
            class: "m-2",
            size: "small",
            placeholder: "Search for icons",
            "suffix-icon": $setup.Search,
            onKeydown: _withKeys($setup.handleSearchClick, ["enter"])
          }, null, 8, ["modelValue", "suffix-icon", "onKeydown"])
        ]),
        ($setup.iconsLoading)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
              [_directive_loading, true]
            ])
          : _createCommentVNode("", true),
        ($setup.iconFinderIcons.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "icons-container",
              ref: "iconFinderWrapper",
              style: _normalizeStyle({ height: $setup.height + 'px' })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.iconFinderIcons, (iconData) => {
                return (_openBlock(), _createElementBlock("img", {
                  draggable: 'true',
                  unselectable: "on",
                  onDrag: ($event: any) => (_ctx.$emit('widget-drag-icon', { type: 'icon-card', url: iconData.vector_sizes[0].formats[0].download_url })),
                  onDragend: ($event: any) => (_ctx.$emit('widget-dragend-icon', { type: 'icon-card', url: iconData.vector_sizes[0].formats[0].download_url })),
                  onClick: ($event: any) => ($setup.handleIconClick(iconData.vector_sizes[0].formats[0].download_url)),
                  onMouseup: ($event: any) => ($setup.handleIconClick(iconData.vector_sizes[0].formats[0].download_url)),
                  class: "icon-finder-item",
                  key: iconData,
                  src: iconData.raster_sizes[4]?.formats[0].preview_url,
                  alt: ""
                }, null, 40, _hoisted_4))
              }), 128)),
              ($setup.showLoadMore)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    onClick: [
                      _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
                      $setup.loadMore
                    ],
                    loading: $setup.loadMoreLoading,
                    type: "info",
                    dark: false,
                    color: "#f2f3f5",
                    plain: "",
                    style: {"color":"#7E8299","border":"0","width":"98%"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Load more")
                    ]),
                    _: 1
                  }, 8, ["onClick", "loading"]))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true)
      ], 32)
    ])
  ], 6)), [
    [_directive_click_outside, $setup.handleClickOutside]
  ])
}