
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'builder-mobile-bottom-menu',
  setup () {
    const activityTemplate = computed(() => useStore().getters.getActivityTemplate)
    const jitsiVisible = ref(false)

    return {
      activityTemplate,
      jitsiVisible
    }
  }
})
