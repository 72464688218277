
import vClickOutside from 'click-outside-vue3'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { Search } from '@element-plus/icons-vue'
export default {
  name: 'TheIconFinderModal',
  props: {
    showModal: { type: Boolean }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },

  emits: ['close-modal', 'widget-drag', 'widget-dragend', 'show-iconfinder', 'widget-drag-icon', 'widget-dragend-icon'],
  setup (props, { emit }) {
    const store = useStore()
    const iconFinderIcons = ref([]) as any
    const searchQuery = ref('')
    const currentPage = ref(0)
    const loadMoreLoading = ref(false)
    const iconsLoading = ref(false)
    const showLoadMore = ref(false)
    const loadMore = () => {
      loadMoreLoading.value = true
      store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: currentPage.value + 1, query: searchQuery.value }).then((result) => {
        iconFinderIcons.value = iconFinderIcons.value.concat(result.payload.data.icons)
        currentPage.value = currentPage.value + 1
        if (result.payload.data.total_count - (currentPage.value + 1) * 52 > 0) {
          showLoadMore.value = true
        } else {
          showLoadMore.value = false
        }
        console.log('iconFinderIcons', iconFinderIcons.value)
        loadMoreLoading.value = false
      }).catch(() => {
        loadMoreLoading.value = false
      })
    }
    const handleSearchClick = (v) => {
      currentPage.value = 0
      iconsLoading.value = true
      iconFinderIcons.value = []

      store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: 0, query: searchQuery.value }).then((result) => {
        console.log('after search', result.payload.data.icons)
        iconFinderIcons.value = result.payload.data.icons
        iconsLoading.value = false
        if (result.payload.data.total_count - 52 > 0) {
          showLoadMore.value = true
        } else {
          showLoadMore.value = false
        }
      }).catch(() => {
        iconsLoading.value = false
      })
    }
    watch(props, () => {
      console.log('props', props)
      if (props.showModal && iconFinderIcons.value.length === 0) {
        iconsLoading.value = true
        store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: 0, query: 'interface' }).then((result) => {
          iconFinderIcons.value = result.payload.data.icons
          iconsLoading.value = false
          if (result.payload.data.total_count - 52 > 0) {
            showLoadMore.value = true
          }
        }).catch(() => {
          iconsLoading.value = false
        })
      }
    }, { deep: true })
    // onMounted(() => {
    //   store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS).then((result) => {
    //     iconFinderIcons.value = result
    //   })
    // })
    const activityTemplate = computed(() => store.getters.getActivityTemplate)

    const handleClickOutside = (e) => {
      if (typeof e.target.className === 'string' && (e.target.className !== 'widget active' && e.target.className !== 'img-widget active' && e.target.className !== 'widget-inner active clicked-widget' && e.target.className !== 'widget-inner active clicked-widget')) {
        emit('close-modal', true)
      }
    }

    const iconFinderWrapper = ref<HTMLDivElement | null>(null)
    const height = ref(null) as any

    const updateHeight = () => {
      // if (iconFinderWrapper.value) {
      // console.log('updating height')
      height.value = window.innerHeight * 0.7 // adjust multiplier as needed
      // }
    }

    const handleIconClick = (downloadUrl) => {
      console.log('downloadUrl', downloadUrl)
      const payload = {
        content: downloadUrl,
        templateUuid: activityTemplate.value.activityTemplateUuid
      }
      store.dispatch(Actions.API_UPLOAD_ICONFINDER_IMAGE, payload).then((result) => {
        console.log('result', result)
      })
    }
    onMounted(() => {
      setTimeout(() => {
        updateHeight()
      }, 0)
      // updateHeight()
      iconFinderWrapper.value?.addEventListener('scroll', () => {
        console.log('scrolling inside')
        // const { scrollTop, scrollHeight, clientHeight } = iconFinderWrapper.value
        // if (scrollTop + clientHeight >= scrollHeight) {
        //   console.log('bottom')
        //   store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: currentPage + 1, query: searchQuery.value }).then((result) => {
        //     console.log('after search', result.payload.data.icons)
        //     iconFinderIcons.value = iconFinderIcons.value.concat(result.payload.data.icons)
        //   })
        // }
      })
      window.addEventListener('resize', updateHeight)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateHeight)
    })

    const onScrollItems = () => {
      console.log('scrolling')
    }

    // function handleResize () {
    //   if (iconFinderWrapper.value) {
    //     console.log('height', height.value)
    //     // height.value = window.innerHeight * 0.8 // adjust multiplier as needed
    //   }
    // }

    return {
      onScrollItems,
      handleClickOutside,
      handleIconClick,
      handleSearchClick,
      loadMore,
      loadMoreLoading,
      searchQuery,
      iconFinderWrapper,
      iconFinderIcons,
      height,
      showLoadMore,
      iconsLoading,
      Search
    }
  }
}
